import React, { useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import "../../stylesheets/custom";
import "../../stylesheets/custom.css";

const Roadmaps = (props) => {
  const [onilne, setOnline] = useState(false);

  return (
    <React.Fragment>
      <section className="projects-area bg-color-black pt-5 pb-5 ">
        <div className="container bg-color-black  pb-5 ">
          <div className="row justify-content-center mt-1 ">
            <React.Fragment>
            <div className="col-12 col-md-12 col-lg-12 pb-5">
                <div className="d-flex flex-column justify-content-center" >
                    <h2 className="rockwell-font pb-3 color-FFFFFF  " style={{ fontSize: '52px' }}>
                    DEVELOPMENT
                        <span style={{ color: '#F2356D' }}> ROADMAP</span>
                    </h2>
                </div>

            </div>
              <div className="col-12 col-md-12 col-lg-12 pb-5">
                  <div className="text-center mx-auto mb-10 text-secondary">
                    <p className="color-FFFFFF">
                      After sell out our goal is to make sure that as the owner of those amazing Art NFTs you get to be part of a privileged group of collectors with a Roadmap worthy of BAYCs and MAYCs. The <span style={{ color: '#F2356D' }}>main highlight</span> of the Roadmap is the creation of an <span style={{ color: '#F2356D' }}>exclusive A.I. Art prize</span> (similar to Lumen Art Prize) on which Mutant Money Society NFT collectors will be able to vote.
                    </p>
                  </div>
              </div>




              <div className="row col-12 px-0 mx-0 justify-center pb-3 border-top border-bottom">
                    <div className="col-5 justify-content-start d-flex align-items-center text-justify color-F2356D">

                    A.I. MUTANT ART PRIZE

                    </div>
                    <div className="col-7 text-left ">
                      <div className="d-flex justify-content-center py-3 text-justify align-items-center text-left" style={{height:"auto", width:"auto"}}>
                          <h6 className="mb-0 pb-0 color-FFFFFF">
                          Given the team experience working with A.I. we want to create a new contest open to all artists creating with A.I. with a cool ETH cash prize and possibility to participate on upcoming collections with us.
                          Holders of the NFT will be able to vote on the winner! Become an art crtitic with us.
                          </h6>
                      </div>
                    </div>

              </div>


              <div className="row col-12 px-0 mx-0 justify-center pb-3 border-top">
                    <div className="col-5 justify-content-start d-flex align-items-center text-justify color-FFFFFF">

                    WHALE COLLECTION

                    </div>
                    <div className="col-7 text-left ">
                      <div className="d-flex justify-content-center py-3 text-justify align-items-center text-left" style={{height:"auto", width:"auto"}}>
                          <h6 className="mb-0 pb-0 color-FFFFFF">
                          We are very thankful to our whales and will reward them accordingly. We will mint a special edition of 25 UNIQUE and different pieces where whales will be able to decide on the design of the main character and the details.
                          This will be part of a sister collection on Open Sea. Have a Banknote designed by you with possibly a loved one on it! Check.
                          </h6>
                      </div>
                    </div>

              </div>

              <div className="row col-12 px-0 mx-0 justify-center pb-3 border-top">
                    <div className="col-5 justify-content-start d-flex align-items-center text-justify color-F2356D">
                    GROW YOUR RANKING
                    </div>
                    <div className="col-7 text-left ">
                      <div className="d-flex justify-content-center py-3 text-justify align-items-center text-left" style={{height:"auto", width:"auto"}}>
                          <h6 className="mb-0 pb-0 color-FFFFFF">
                          Be heard in the community. Being an active member of the community will allow you to work your way up and have a bigger power of decision through votes and surveys. Your ranking will also vary depending on the number of NFTs you own. Become a Whale!
                          </h6>
                      </div>
                    </div>

              </div>

              <div className="row col-12 px-0 mx-0 justify-center pb-3 border-top">
                    <div className="col-5 justify-content-start d-flex align-items-center text-justify color-FFFFFF">

                        SIGNIFICANT COLLABORATIONS

                    </div>
                    <div className="col-7 text-left ">
                      <div className="d-flex justify-content-center py-3 text-justify align-items-center text-left" style={{height:"auto", width:"auto"}}>
                          <h6 className="mb-0 pb-0 color-FFFFFF">
                              We will work on setting up partnerships with other projects and brands. Imagine that brands like VISA have bought those skinny punks to decorate their credit cards. Image what would happen if a credit card company picked up some of MUTANT MONEY SOCIETY designs to decorate their exclusive VIP cards!
                              Since you own the rights to the design of the NFT you have bought, the sky is the limit. We work on a concept called "infinite design", which allows by using A.I. to create an infinite amount of material to go on merchandising and cards.
                          </h6>
                      </div>
                    </div>

              </div>


              <div className="row col-12 px-0 mx-0 justify-center pb-3 border-top">
                    <div className="col-5 justify-content-start d-flex align-items-center text-justify color-F2356D ">

                    METAVERSE READY

                    </div>
                    <div className="col-7 text-left ">
                      <div className="d-flex justify-content-center py-3 text-justify align-items-center text-left" style={{height:"auto", width:"auto"}}>
                          <h6 className="mb-0 pb-0 color-FFFFFF">
                          The MMS collection is Metaverse ready and can be displayed in Decentraland and other upcoming Metaverses. Since they are modern works of Art, we hope collectors will display them with pride.
                          </h6>
                      </div>
                    </div>

              </div>

              <div className="row col-12 px-0 mx-0 justify-center pb-3 border-top border-bottom">
                    <div className="col-5 justify-content-start d-flex align-items-center text-justify color-FFFFFF">

                    COMPLETENESS

                    </div>
                    <div className="col-7 text-left ">
                      <div className="d-flex justify-content-center py-3 text-justify align-items-center text-left" style={{height:"auto", width:"auto"}}>
                          <h6 className="mb-0 pb-0 color-FFFFFF">
                          This is a versatily project including design and characters. As such this project can be loved for the Art value but can also be used as PFP. You get the best of both worlds.
                            </h6>
                      </div>
                    </div>

              </div>




            <div className="row col-12 px-0 mx-0 justify-center pb-3 border-top">
                  <div className="col-5 justify-content-start d-flex align-items-center text-justify color-F2356D ">

                  NETWORK EFFECT

                  </div>
                  <div className="col-7 text-left ">
                    <div className="d-flex justify-content-center py-3 text-justify align-items-center text-left" style={{height:"auto", width:"auto"}}>
                        <h6 className="mb-0 pb-0 color-FFFFFF">

                        This is a community of high status individuals in society, whether it in business, sports or art. As such, we want to make it an exclusive opportunity to network, cross sell, expand your horizons and above all make new friends.
                        When you own a MMS NFT you have something in common, it can be the start if a relationship.
                        We create a fenced environment for members to communicate and network.

                        </h6>
                    </div>
                  </div>

            </div>




            <div className="row col-12 px-0 mx-0 justify-center pb-3 border-top">
                  <div className="col-5 justify-content-start d-flex align-items-center text-justify color-FFFFFF ">

                  PRIORITY ACCESS

                  </div>
                  <div className="col-7 text-left ">
                    <div className="d-flex justify-content-center py-3 text-justify align-items-center text-left" style={{height:"auto", width:"auto"}}>
                        <h6 className="mb-0 pb-0 color-FFFFFF">

                        Wanna skip the waiting list queue on the next project? Get in early on this one and be assured to be treated like royalty for the following project by MUTANT MONEY SOCIETY (Q3-2022).
                        Benefit from lower pricing than regular collectors. It pays to be an early bird.

                        </h6>
                    </div>
                  </div>

            </div>





            <div className="row col-12 px-0 mx-0 justify-center pb-3 border-top">
                  <div className="col-5 justify-content-start d-flex align-items-center text-justify color-F2356D ">

                  NEW ASSOCIATED ARTISTS

                  </div>
                  <div className="col-7 text-left ">
                    <div className="d-flex justify-content-center py-3 text-justify align-items-center text-left" style={{height:"auto", width:"auto"}}>
                        <h6 className="mb-0 pb-0 color-FFFFFF">

                        We wanna give you the authority to vote on the artists participating in the next iterations of the collection. You can be in the position to commission new works, just like old world art moguls.


                        </h6>
                    </div>
                  </div>

            </div>




            </React.Fragment>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

Roadmaps.propTypes = {
  artWorksData: PropTypes.array,
  S3_ADDRESS: PropTypes.string,
};

export default Roadmaps;
