import React from "react"
import PropTypes from "prop-types"

import {  RecoilRoot } from 'recoil'

import NavbarTwoCustom from './_App/NavbarTwoCustom';  //   <NavbarTwoCustom />
//import Solutions from './MachineLearningAISolutions/Solutions';
import Buynow from './MachineLearningAISolutions/BuyNow';
import PreSales from './MachineLearningAISolutions/PreSales';
//import Solutions1 from './MachineLearningAISolutions/Solutions1';
import Solutions2 from './MachineLearningAISolutions/Solutions2';
import Solutions3 from './MachineLearningAISolutions/Solutions3';

//import Solutions4 from './MachineLearningAISolutions/Solutions4';
import Solutions5 from './MachineLearningAISolutions/Solutions5';
import Denominations from './MachineLearningAISolutions/Denominations';
import Roadmaps from './MachineLearningAISolutions/Roadmaps';
import Mintdate from './MachineLearningAISolutions/Mintdate';
import HowToWhitelist from './MachineLearningAISolutions/HowToWhitelist';
import Roadmaps2 from './MachineLearningAISolutions/Roadmaps2';
//import ContractNFT from './MachineLearningAISolutions/ContractNFT';
import FAQ from './Common/FAQ'
import ToastCommonWrap from './Common/ToastCommon'
import Footer from './_App/Footer';
import GoTop from './_App/GoTop';  //   <NavbarTwoCustom />
import Web3 from 'web3';
import mutantmoneysociety from '../contracts/Mutantmoneysociety.json'

class RootPage extends React.Component {

  constructor(props) {
    super(props);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const successParam = urlParams.has('success');
    var s_type = "";
    var s_msg = "";
    if (successParam) {
       //console.log("constructor setting")
        window.history.pushState(null, null, window.location.pathname);
        s_type = "success"
        s_msg = urlParams.get('success')
    }
    else{

    }

    this.state = {
      currentPrice: 0.1,
      sendType:s_type,
      msg:s_msg
    };




  }



  //mounting the main Element
  async componentDidMount() {

    await this.loadWeb3_currentPrice(this.props.dispatch)



  }


  //network and web3 logic

  async loadWeb3_currentPrice(dispatch) {
    //console.log("loadWeb3")
    if (window.ethereum) {
      //console.log("Starting up....")
      const web3 = new Web3(window.ethereum)    // METAMASK CONNECTION
      const netId = await web3.eth.net.getId()     // gets the value of the network
      const enabledWeb3 = await ethereum.enable();
      const accounts = await web3.eth.getAccounts()

      //load balance
      if (typeof accounts[0] !== 'undefined') {
        //console.log("-----getting balance-----")
        //console.log("current account -> ",accounts[0])
        const balance = await web3.eth.getBalance(accounts[0])
        //console.log("-----balance-----")
        //console.log(balance)
        //console.log("balance", web3.utils.fromWei(balance, 'ether'), "ETH")

        this.setState({ account: accounts[0], balance: balance, web3: web3 })

        //console.log("-----we done here-----")

      } else {
        //window.alert('Please login with MetaMask')

      }

      //load contracts
      try {



        //console.log("mutantmoneysociety.networks[netId].address  ->",mutantmoneysociety.networks[netId].address)

        const contract = new web3.eth.Contract(mutantmoneysociety.abi, mutantmoneysociety.networks[netId].address)  // comes from ABI json file
        //get the current price

        const currentPrice_wei = await contract.methods.MMSNFTPrice().call().catch(error => {
          return web3.utils.toWei('0.1', 'ether')
        });

        this.setState({ currentPrice: web3.utils.fromWei(currentPrice_wei, 'ether') })
        //console.log("currentPrice", web3.utils.fromWei(currentPrice_wei, 'ether'), "ETH")

      } catch (e) {
        console.log('Error', e)
        window.alert('ERRORs -- Generic error in the web3')
      }

    } else {
      window.alert('Please install MetaMask')
    }
  }   // END OF LOADING block chain data


  /*
  <NavbarTwoCustom/>
  */
  render () {

        return (

          <React.Fragment>
          <RecoilRoot>
          
          <ToastCommonWrap sendType={this.state.sendType} msg={this.state.msg}/>
          <PreSales S3_ADDRESS={this.props.S3_ADDRESS}  preSalesIsEnable={this.props.preSalesIsEnable}/>
          <Buynow S3_ADDRESS={this.props.S3_ADDRESS}  salesIsOn={this.props.salesIsOn}/>
          <Solutions2 artWorksData={this.props.artWorksData} S3_ADDRESS={this.props.S3_ADDRESS}  SALE_START_TIMESTAMP={this.props.SALE_START_TIMESTAMP} currentPrice={this.state.currentPrice} TOTAL_ITEMS={this.props.TOTAL_ITEMS} UNIQUE_PRICE={this.props.UNIQUE_PRICE} PRESALESTART={this.props.PRESALESTART}  salesIsOn={this.props.salesIsOn}/>
          <Mintdate S3_ADDRESS={this.props.S3_ADDRESS} />
          <Solutions3 artWorksData={this.props.artWorksData} S3_ADDRESS={this.props.S3_ADDRESS}  SALE_START_TIMESTAMP={this.props.SALE_START_TIMESTAMP} currentPrice={this.state.currentPrice} TOTAL_ITEMS={this.props.TOTAL_ITEMS} UNIQUE_PRICE={this.props.UNIQUE_PRICE} PRESALESTART={this.props.PRESALESTART}  salesIsOn={this.props.salesIsOn}/>
          <HowToWhitelist S3_ADDRESS={this.props.S3_ADDRESS} />
          <Denominations artWorksData={this.props.artWorksData} S3_ADDRESS={this.props.S3_ADDRESS}  SALE_START_TIMESTAMP={this.props.SALE_START_TIMESTAMP} currentPrice={this.state.currentPrice} TOTAL_ITEMS={this.props.TOTAL_ITEMS} UNIQUE_PRICE={this.props.UNIQUE_PRICE} PRESALESTART={this.props.PRESALESTART}  salesIsOn={this.props.salesIsOn}/>
          <Roadmaps2 S3_ADDRESS={this.props.S3_ADDRESS} />
          <Roadmaps S3_ADDRESS={this.props.S3_ADDRESS} />
          <FAQ />
          <Solutions5 artWorksData={this.props.artWorksData} S3_ADDRESS={this.props.S3_ADDRESS}  SALE_START_TIMESTAMP={this.props.SALE_START_TIMESTAMP} currentPrice={this.state.currentPrice} TOTAL_ITEMS={this.props.TOTAL_ITEMS} UNIQUE_PRICE={this.props.UNIQUE_PRICE} PRESALESTART={this.props.PRESALESTART}  salesIsOn={this.props.salesIsOn}/>
          <Footer S3_ADDRESS={this.props.S3_ADDRESS} user_signed_in={this.props.user_signed_in} />
          <GoTop />
          </RecoilRoot>
        </React.Fragment>

        )

        /*
        return (<div>Coming up</div>)
        */

  }



}




RootPage.propTypes = {
  artWorksData: PropTypes.array,
  S3_ADDRESS: PropTypes.string
};

export default RootPage
