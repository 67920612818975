import React from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';


const FAQ = () => {
    return (
        <section className="what-we-do-area bg-color-black pt-100 pb-70">

            <div className="container ">

                <div className="col-12 pb-3 ">
                    <div className="d-flex flex-column justify-content-center" >
                        <h2 className="rockwell-font pb-3 color-FFFFFF  pb-4" style={{ fontSize: '52px' }}>
                            THE
                                            <span style={{ color: '#F2356D' }}> FAQ</span>
                        </h2>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-12 pb-5 border-line-bottom">
                        <div className="faq-accordion">
                            <Accordion allowZeroExpanded preExpanded={['a']}>

                                <AccordionItem uuid="a">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            When is the Drop?
                            </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p className="py-3">The mint is on the 10th April 2022 at 17.00UTC. The Presale is on the 09th April 2022 at 17.00UTC</p>
                                    </AccordionItemPanel>
                                </AccordionItem>


                                <AccordionItem uuid="b">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            How can I get this NFT?
                            </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p className="py-3">Mutant Money Society NFTs can be minted on our website for the initial drop: <a href="https://www.mutantmoney.art" className="font-size-16 font-weight-bold" target="_blank">MUTANT MONEY SOCIETY</a> . Afterwards they will be available for purchase on <a href="https://opensea.io/collection/mutantmoneysociety" className="font-size-16 font-weight-bold" target="_blank">OpenSea.io</a>.
                                     Another chance to get one is to participate in Giveaways and Competitions.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>


                                <AccordionItem uuid="c">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            How to buy?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <a href="/getnfts" className="py-3">See Details.</a>
                                    </AccordionItemPanel>
                                </AccordionItem>



                                <AccordionItem uuid="d">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Why is this collection unique?
                                </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p className="py-3">The project is unique by the nature of the art (Have you seen anything like it before?). It has Humans, it has Apes and it is part of the up and coming #nftpopart Art movement. We are also proud to have Star Artist D'Agostinoai working with us to created those amazing pieces.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>


                                <AccordionItem uuid="e">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            What are the rarities?
                                </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p className="py-3">There are rarities in the collection based on several aspects of the banknote. We think you can figure out most of them by examining the works, if not we will give you some clues - ask us in Discord!  </p>
                                    </AccordionItemPanel>
                                </AccordionItem>



                                <AccordionItem uuid="f">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Where can I sell Mutant Money Society NFTs after buying them?
                                </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>

                                    <p> Please check the Official <a href="" className="font-size-16 font-weight-bold" target="_blank">Mutant Money Society</a> collection On <a href="https://opensea.io/collection/mutantmoneysociety" className="font-size-16 font-weight-bold" target="_blank">Open Sea</a></p>


                                    </AccordionItemPanel>
                                </AccordionItem>


                                <AccordionItem uuid="g">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            When are the NFTs revealed?
                                </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p className="py-3">They are revealed a few hours after the sale is closed to prevent early snipers from buying the good ones quietly!</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem uuid="h">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        The contract address
                                </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p className="py-3">0x68eC932CCb3B1aac03766A3F2f3D90B7603f2Cd3 <a href="https://etherscan.io/token/0x68eC932CCb3B1aac03766A3F2f3D90B7603f2Cd3" className="font-size-16 font-weight-bold" target="_blank">See it on etherscan</a></p>
                                    </AccordionItemPanel>
                                </AccordionItem>





                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>


        </section>
    )
}

export default FAQ;
