import React, { useState } from 'react';
import ReactDOM from "react-dom";
import PropTypes from "prop-types"
import CountDown from './CountDown';
import '../../stylesheets/custom'
import '../../stylesheets/custom.css'

// import Percent20 from "./Percent20"
// import Percent40 from "./Percent40"
// import Percent60 from "./Percent60"
//import Percent80 from "./Percent80"
// import Percent100 from "./Percent100"

// import Percent25 from "./Percent25"
// import Percent50 from "./Percent50"
// import Percent75 from "./Percent75"
// import Percent100 from "./Percent100_2"

// import testMP4 from './../../../videos/test.mp4'
//import BackgroundFrame from './../../../images/frame.png'

import tiktokSVG from './../../../images/tiktok.svg';


const Solutions2 = (props) => {

    const [onilne, setOnline] = useState(false);


    return (
        <React.Fragment>
            <section className="projects-area bg-color-black pt-5 pb-5 ">
                <div className="container bg-color-black  pb-5 border-line-bottom">
                    <div className="row justify-content-center mt-1 ">
                        {false ?
                            <div className="col-11 pb-5 ">
                                <div className="d-flex justify-content-center">


                                    <a href="/transactions" className="px-5 py-5" >
                                        <img src={props.S3_ADDRESS + "webimages/buy_now_resize.png"} className="banner-img " alt="distribution" />
                                    </a>
                                </div>
                            </div>
                            :
                            <React.Fragment>
                                <div className="col-10 col-md-10 col-lg-5 pb-0 px-0 order-lg-1 order-md-1">
                                    <div className="d-flex flex-column justify-content-center" >

                                        <h2 className="rockwell-font" style={{ color: '#ffffff', fontSize: '48px' }}>
                                            THE
                                            <span style={{ color: '#F2356D' }}> NFT ART COLLECTION</span>
                                        </h2>

                                        <div className="p-2  rockwell-font pb-0" >
                                            <p style={{ color: '#ffffff', fontSize: '20px', fontWeight: '500' }}>
                                                Mutant Money Society is a private <span style={{ color: '#F2356D' }}>Art</span> collection of 6666 #NFT #ETH unique Bills. The Banknote designs are stored as ERC-721 tokens and hosted on IPFS.
                                            </p>
                                            <br />
                                            <p style={{ color: '#ffffff', fontSize: '20px', fontWeight: '500' }}>
                                                We have teamed up with up with star artist <span style={{ color: '#F2356D' }}>D'AgostinoAI</span> to create dope and cutting-edge art. We have used <span style={{ color: '#F2356D' }}>A.I.</span> to make all artworks unique and impossible to forge.
                                                The project is one of a kind endeavor created by a global team based in Europe and Asia.
                                                This is a chance to <span style={{ color: '#F2356D' }}> join </span>an exclusive project and get in touch with like minded people - Business people from the NFT, trading and crypto community. This is more than just art, this is your <span style={{ color: '#F2356D' }}>ticket into our community</span>.
                                            </p>
                                            <br />
                                            <p style={{ color: '#ffffff', fontSize: '20px', fontWeight: '500' }}>

                                            </p>
                                            <br />


                                        </div>

                                        <a href="https://discord.gg/Myn8Uw29Eh" target="_blank" className="d-flex flex-column  align-items-center ">
                                            <i className='d-flex  justify-content-center bx bxl-discord bx-normal-white-custom' style={{ width: "100%",color: '#F2356D'  }}>

                                            </i>
                                            <p style={{ color: '#F2356D'  }}>Discord</p>

                                        </a>


                                    </div>

                                </div>
                                <div className="col-8 col-md-10 col-lg-7  pl-3 pr-1 pt-0 mt-0 pb-0 order-lg-2 order-md-3" >

                                    <div className="d-flex mt-lg-0 justify-content-center align-content-center" >
                                        <img src={props.S3_ADDRESS + "website/mms-v2.gif"} className=" " alt="distribution" />
                                    </div>
                                    <div className="d-flex mt-lg-4 justify-content-center align-content-center" >
                                        <img src={props.S3_ADDRESS + "website/xreal-12.jpg"} className=" " alt="distribution" />
                                    </div>


                                </div>


                            </React.Fragment>

                        }
                    </div>




                </div>
            </section>

        </React.Fragment>
    )
}

Solutions2.propTypes = {
    artWorksData: PropTypes.array,
    S3_ADDRESS: PropTypes.string
};

export default Solutions2;
