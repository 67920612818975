import React, { useState } from 'react';
import ReactDOM from "react-dom";
import PropTypes from "prop-types"
import CountDown from './CountDown';
import '../../stylesheets/custom'
import '../../stylesheets/custom.css'

// import Percent20 from "./Percent20"
// import Percent40 from "./Percent40"
// import Percent60 from "./Percent60"
//import Percent80 from "./Percent80"
// import Percent100 from "./Percent100"

// import Percent25 from "./Percent25"
// import Percent50 from "./Percent50"
// import Percent75 from "./Percent75"
// import Percent100 from "./Percent100_2"

// import testMP4 from './../../../videos/test.mp4'
//import BackgroundFrame from './../../../images/frame.png'

import tiktokSVG from './../../../images/tiktok.svg';


const Buynow = (props) => {

    const [onilne, setOnline] = useState(false);


    return (
        <React.Fragment>
                        {props.salesIsOn ?
                            <React.Fragment>
                            <section className="projects-area bg-color-black pt-5 pb-5 ">
                                <div className="container bg-color-black  pb-5 border-line-bottom">
                                    <div className="row justify-content-center mt-1 ">
                                        <div className="col-10 col-md-10 col-lg-5 pb-0 px-0 order-lg-1 order-md-1">
                                            <a href="/transactions" target="_self" className="d-flex flex-column  align-items-center ">

                                                <img src={props.S3_ADDRESS + "website/buynow.png"} alt="Buy Now" className="" />

                                            </a>
                                        </div></div>
                                </div>
                                </section>
                            </React.Fragment>

                            :
                            <React.Fragment>

                            </React.Fragment>

                        }

        </React.Fragment>
    )
}

Buynow.propTypes = {
    artWorksData: PropTypes.array,
    S3_ADDRESS: PropTypes.string
};

export default Buynow;
