import React from "react";
import PropTypes from "prop-types";
import "../../stylesheets/custom";
import "../../stylesheets/custom.css";

const Mintdate = (props) => {

  return (
    <React.Fragment>
      <section className="projects-area bg-color-black pt-5 pb-5 ">
        <div className="container bg-color-black  pb-5 border-line-bottom">
          <div className="row justify-content-center mt-1 ">
            <React.Fragment>

              <div className="col-12 col-md-12 col-lg-12 pb-5 ">
                <div className="d-flex flex-column justify-content-center" >
                  <h2 className="rockwell-font pb-3 color-FFFFFF  " style={{ fontSize: '52px' }}>
                    THE
                                            <span style={{ color: '#F2356D' }}> MINT DATE</span>
                  </h2>


                </div>
                <div className="col-12 col-md-12 col-lg-12 pb-5  text-center">
                  <img src={props.S3_ADDRESS + "website/mint.png"} alt="Mint" className="pr-4" style={{ maxWidth: '41.8%', height: 'auto' }} />
                  <img src={props.S3_ADDRESS + "website/pre-sale-local.jpg"} alt="Mint" className="pl-4" style={{ maxWidth: '58%', height: 'auto' }} />
                </div>


              </div>
            </React.Fragment>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

Mintdate.propTypes = {
  artWorksData: PropTypes.array,
  S3_ADDRESS: PropTypes.string,
};

export default Mintdate;
