import React from 'react';

const Footer = (props) => {

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area  bg-color-mint">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-7">
                        <div className="single-footer-widget">

                            <a href="/" className="logo">
                                <img src={props.S3_ADDRESS + "website/logofp.png"} alt="logo" />
                            </a>

                            <div className="d-flex justify-content-center">
                                <ul className="social-link">
                                    <li>

                                        <a href="https://twitter.com/@niftycashart" className="d-block" target="_blank">
                                            <i className='bx bxl-twitter'></i>
                                        </a>

                                    </li>
                                    <li>

                                        <a href="https://discord.gg/Myn8Uw29Eh" className="d-block" target="_blank">
                                            <i className='bx bxl-discord'></i>
                                        </a>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-sm-5">
                        <div className="footer-bottom-area-custom-vertical rockwell-font" >
                            <div className="d-flex justify-content-end ">
                                <h3 style={{ color: "#F2356D" }}>Explore</h3>
                            </div>
                            <ul className="vertical footer-links-list-5 pb-3">
                                <li>

                                    <a href="/team" className="rockwell-font"> <b>Team</b></a>

                                </li>


                                <li>

                                    <a href="/terms_of_service" className="rockwell-font"> <b>Terms & Conditions</b></a>

                                </li>
                                <li>

                                    <a href="/disclaimer" className="rockwell-font"> <b>Disclaimer</b></a>

                                </li>
                                <li>

                                    <a href="/getnfts" className="rockwell-font"> <b>How to Buy</b></a>

                                </li>

                                {props.user_signed_in ?

                                    <li >
                                        <a href="/dashboard" className="rockwell-font"><b>Dashboard</b></a>
                                    </li>
                                    :
                                    <div />
                                }

                                <li>
                                    <a href="https://raritysniper.com/" className="rockwell-font pt-3">
                                        <img src={props.S3_ADDRESS + "website/logo2-white.png"} alt="raritysniper" style={{ maxWidth: "25%" }} />
                                    </a>
                                </li>


                                <li>

                                    <a href="https://www.nftjet.com" className="rockwell-font pt-3" >
                                        <img alt="Logo" src="https://www.nftjet.com/assets/media/logos/logo.png" style={{ maxWidth: "8%" }} />

                                    </a>
                                </li>



                                <li>
                                    <div/>
                                </li>

                            </ul>

                        </div>

                    </div>


                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="footer-bottom-area rockwell-font" style={{ marginTop: "0px" }}>
                            <div className="d-flex justify-content-end justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end pt-40">
                                <p>Copyright &copy;{currentYear} <strong>niftycash</strong> All rights reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    );
}

export default Footer;
