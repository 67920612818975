import React, { useState } from 'react';
import ReactDOM from "react-dom";
import PropTypes from "prop-types"
import CountDown from './CountDown';
import '../../stylesheets/custom'
import '../../stylesheets/custom.css'

// import Percent20 from "./Percent20"
// import Percent40 from "./Percent40"
// import Percent60 from "./Percent60"
//import Percent80 from "./Percent80"
// import Percent100 from "./Percent100"

import Percent25 from "./Percent25"
import Percent50 from "./Percent50"
import Percent75 from "./Percent75"
import Percent100 from "./Percent100_2"

import testMP4 from './../../../videos/test.mp4'


const Solutions5 = (props) => {

    const [onilne, setOnline] = useState(false);


    return (
        <React.Fragment>
            <section className="projects-area bg-color-black pt-5 pb-5 ">
                <div className="container bg-color-black ">
                    <div className="row justify-content-center mt-1 ">

                        <React.Fragment>
                            <div className="col-12 col-md-12 col-lg-12 pb-5 ">
                                <div className="d-flex flex-column justify-content-center" >
                                    <h2 className="rockwell-font" style={{ color: '#ffffff', fontSize: '52px' }}>
                                    WHAT'S IN YOUR
                                            <span style={{ color: '#F2356D' }}> WALLET</span>?
                                    </h2>

                                </div>

                            </div>
                            <div className="col-5 col-md-5 col-lg-3 pb-0 pb-md-4    justify-content-center align-content-center">

                                <img src={props.S3_ADDRESS + "website/xreal-7.jpg"} className="" alt="distribution" />

                            </div>
                            <div className="col-5 col-md-5 col-lg-3 pb-0 pb-md-4  justify-content-center align-content-center">

                                <img src={props.S3_ADDRESS + "website/xreal-5.jpg"} className="" alt="distribution" />
                            </div>

                            <div className="col-5 col-md-5 col-lg-3    pb-0  justify-content-center align-content-center">
                                <img src={props.S3_ADDRESS + "website/xreal-11.jpg"} className="" alt="distribution" />

                            </div>
                            <div className="col-5 col-md-5 col-lg-3 pb-0 pb-md-4   justify-content-center align-content-center">
                                <img src={props.S3_ADDRESS + "website/xreal-2.jpg"} className="" alt="distribution" />
                            </div>



                            <div className="col-12 col-md-12 col-lg-12 pt-3 ">
                                <div className="d-flex justify-content-center align-items-center">
                                    <p className="rockwell-font" style={{ color: '#ffffff', fontSize: '26px', fontWeight: '500' }} > Want Some? Discord is LIVE now</p>
                                    <a href="https://discord.gg/Myn8Uw29Eh" className="d-block" style={{ width: "80px" }} target="_blank">
                                            <i className='d-flex justify-content-center bx bxl-discord bx-normal-custom'></i>

                                    </a>
                                </div>

                            </div>


                        </React.Fragment>

                    </div>

                </div>
            </section>

        </React.Fragment>
    )
}

Solutions5.propTypes = {
    artWorksData: PropTypes.array,
    S3_ADDRESS: PropTypes.string
};

export default Solutions5;
