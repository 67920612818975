import React ,{ useEffect }from "react"
import PropTypes from "prop-types"

import { ToastProvider, useToasts} from 'react-toast-notifications';


const ToastCommon = (props) => {
    const { addToast } = useToasts();
  
    const loadDataOnlyOnce = (props) => {

        //console.log(props)

        if (props.sendType == "success")
        {
            addToast( props.msg, { appearance: 'success' });
        }
        else if(props.toastSend == "info"){
            addToast( props.msg, { appearance: 'info' });
        }
        else if(props.toastSend == "error"){
            addToast( props.msg, { appearance: 'error' });
        }

    };
    


    useEffect(() => {
        loadDataOnlyOnce(props);
      }, [])


  
    return <div/>;
  };

class ToastCommonWrap extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (

            <ToastProvider autoDismiss autoDismissTimeout={10000}>
                <ToastCommon
                    sendType={this.props.sendType}
                    msg={this.props.msg} />
            </ToastProvider>
        )
    }
}

ToastCommonWrap.propTypes = {
    sendType: PropTypes.string,
    msg: PropTypes.string
};

export default ToastCommonWrap