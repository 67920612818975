import React, { useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import "../../stylesheets/custom";
import "../../stylesheets/custom.css";

const Roadmaps2 = (props) => {
    const [onilne, setOnline] = useState(false);

    return (
        <React.Fragment>
            <section className="projects-area  bg-color-black pt-5 pb-5 ">
                <div className="container  bg-color-black border-line-bottom pb-5">
                    <div className="row justify-content-center mt-1 ">
                        <React.Fragment>
                            <div className="col-12 col-md-12 col-lg-12 pb-5">
                                <div className="d-flex flex-column justify-content-center" >
                                    <h2 className="rockwell-font pb-3 color-FFFFFF  " style={{ fontSize: '52px' }}>
                                    SALES
                                        <span style={{ color: '#F2356D' }}> ROADMAP</span>
                                    </h2>
                                </div>

                            </div>

                            <div className="row col-12 px-0 mx-0 justify-content-center pb-3">
                                <div className="col-2 d-flex justify-content-center flex-column align-middle">
                                    <div className="d-flex justify-content-center">

                                        <div className="d-flex rounded-full justify-content-center align-items-center" style={{ width: "100px", height: "100px" }} >
                                            <img src={props.S3_ADDRESS + "website/room.png"}  alt="room.png" className=""></img>
                                        </div>
                                    </div>
                                    <div className="justify-content-center text-center color-FFFFFF  pt-2">10 %</div>
                                </div>
                                <div className="col-10 text-left space-y-1 sm:space-y-3">
                                    <p className="uppercase font-semibold color-F2356D">
                                        INFINITE OBJECTS DROP
                                    </p>
                                    <div className="d-flex flex-even flex-column align-content-start align-items-start mx-1">
                                        <ul className="pl-4" style={{ listStyleType: "disc" }}>
                                            <li className="mt-1 text-secondary  text-start">We're sending 5 Infinite Objects NFT Displays to Holders.</li>
                                            <li className="mt-1 text-secondary  text-start">Admire your NFTs right in front of you IRL.</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>



                            <div className="row col-12 px-0 mx-0 justify-center pb-3">
                                <div className="col-2 d-flex justify-content-center flex-column align-middle">
                                    <div className="d-flex justify-content-center">
                                        <div className="d-flex rounded-full justify-content-center align-items-center" style={{ width: "100px", height: "100px" }} >
                                            <img src={props.S3_ADDRESS + "website/computer.png"}  alt="room.png" className=""></img>
                                        </div>
                                    </div>
                                    <div className="justify-content-center text-center color-FFFFFF  pt-2">25 %</div>
                                </div>
                                <div className="col-10 text-left space-y-1 sm:space-y-3">
                                    <p className="uppercase font-semibold color-F2356D">
                                        CHARITY WORK & DONATIONS
                                    </p>
                                    <div className="d-flex flex-even flex-column align-content-start align-items-start mx-1">
                                        <ul className="pl-4" style={{ listStyleType: "disc" }}>
                                            <li className="mt-1 text-secondary  text-start">Main 3,000 USD donation to victims of War. We hope for peace Globally.</li>
                                            <li className="mt-1 text-secondary  text-start">Global politics are far from the metaverse, but they still impoact each and everyone of us.</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>



                            <div className="row col-12 px-0 mx-0 justify-center pb-3">
                                <div className="col-2 d-flex justify-content-center flex-column align-middle">
                                    <div className="d-flex justify-content-center">
                                        <div className="d-flex rounded-full justify-content-center align-items-center" style={{ width: "100px", height: "100px" }} >
                                            <img src={props.S3_ADDRESS + "website/computer.png"} alt="room.png" className=""></img>
                                        </div>
                                    </div>
                                    <div className="justify-content-center text-center color-FFFFFF  pt-2">50 %</div>
                                </div>
                                <div className="col-10 text-left space-y-1 sm:space-y-3">
                                    <p className="uppercase font-semibold color-F2356D">
                                        EXCLUSIVE WALL ART AND MERCH
                                    </p>
                                    <div className="d-flex flex-even flex-column align-content-start align-items-start mx-1">
                                        <ul className="pl-4" style={{ listStyleType: "disc" }}>
                                            <li className="mt-1 text-secondary  text-start">We give away Awesome IRL Art prizes you can have on your walls.</li>
                                            <li className="mt-1 text-secondary  text-start">Want to drink your coffee from a Mutant Money mug while checking out the crypto currency levels? We have you covered.</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>




                            <div className="row col-12 px-0 mx-0 justify-center pb-3">
                                <div className="col-2 d-flex justify-content-center flex-column align-middle">
                                    <div className="d-flex justify-content-center">
                                        <div className="d-flex rounded-full justify-content-center align-items-center" style={{ width: "100px", height: "100px" }} >
                                        <img src={props.S3_ADDRESS + "website/upgrade.png"} alt="room.png" className=""></img>
                                        </div>
                                    </div>
                                    <div className="justify-content-center text-center color-FFFFFF  pt-2">75 %</div>
                                </div>
                                <div className="col-10 text-left space-y-1 sm:space-y-3">
                                    <p className="uppercase font-semibold color-F2356D">
                                        FLOOR SUPPORT FUND
                      </p>
                                    <div className="d-flex flex-even flex-column align-content-start align-items-start mx-1">
                                        <ul className="pl-4" style={{ listStyleType: "disc" }}>
                                            <li className="mt-1 text-secondary  text-start">We set aside 15 ETH to buy NFTs at floor for giveaways.</li>
                                            <li className="mt-1 text-secondary  text-start">This has been a proven mechanism to mitigate paperhands effect in many project. We've got your back!</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div className="row col-12 px-0 mx-0 justify-center pb-3">
                                <div className="col-2 d-flex justify-content-center flex-column align-middle">
                                    <div className="d-flex justify-content-center">
                                        <div className="d-flex rounded-full justify-content-center align-items-center" style={{ width: "100px", height: "100px" }} >
                                        <img src={props.S3_ADDRESS + "website/liquidity.png"}  alt="room.png" className=""></img>
                                        </div>
                                    </div>
                                    <div className="justify-content-center text-center color-FFFFFF  pt-2">85 %</div>
                                </div>
                                <div className="col-10 text-left space-y-1 sm:space-y-3">
                                    <p className="uppercase font-semibold color-F2356D">
                                        MEMBERS RANKING
                      </p>
                                    <div className="d-flex flex-even flex-column align-content-start align-items-start mx-1">
                                        <ul className="pl-4" style={{ listStyleType: "disc" }}>
                                            <li className="mt-1 text-secondary  text-start">By being an active collector and insightful contributor you can have your voice heard in the community.</li>
                                            <li className="mt-1 text-secondary  text-start">We listen to your feedback.</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                            <div className="row col-12 px-0 mx-0 justify-center pb-3">
                                <div className="col-2 d-flex justify-content-center flex-column align-middle">
                                    <div className="d-flex justify-content-center">
                                        <div className="d-flex rounded-full justify-content-center align-items-center" style={{ width: "100px", height: "100px" }} >
                                        <img src={props.S3_ADDRESS + "website/charity.png"} alt="room.png" className=""></img>
                                        </div>
                                    </div>
                                    <div className="justify-content-center text-center color-FFFFFF  pt-2">100 %</div>
                                </div>
                                <div className="col-10 text-left space-y-1 sm:space-y-3">
                                    <p className="uppercase font-semibold color-F2356D">
                                        SELL OUT MADNESS PARTY
                      </p>
                                    <div className="d-flex flex-even flex-column align-content-start align-items-start mx-1">
                                        <ul className="pl-4" style={{ listStyleType: "disc" }}>
                                            <li className="mt-1 text-secondary  text-start">We give away amazing BLUE CHIP NFTs like there is no tomorrow.</li>
                                            <li className="mt-1 text-secondary  text-start">Our work is amazing but we acknowledge the historical value of works before us.</li>

                                        </ul>
                                    </div>
                                </div>

                            </div>

                        </React.Fragment>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

Roadmaps2.propTypes = {
    artWorksData: PropTypes.array,
    S3_ADDRESS: PropTypes.string,
};

export default Roadmaps2;
