import React from 'react';

const GoTop = ({scrollStepInPx, delayInMs}) => {

    const [thePosition, setThePosition] = React.useState(false);
    const timeoutRef = React.useRef(null);
    const scrollFunc = () =>{
        if (window.scrollY > 170) {
            setThePosition(true)
        } else {
            setThePosition(false);
        }
    }

    React.useEffect(() => {
        document.addEventListener("scroll", scrollFunc);
        return () => {
            setThePosition(false);
            timeoutRef.current = false;
            document.removeEventListener("scroll", scrollFunc);
          };
    }, [])
    
    const onScrollStep = () => {
        if (window.pageYOffset === 0){
            clearInterval(timeoutRef.current);
        }
        window.scroll(0, window.pageYOffset - scrollStepInPx);
    }

    const scrollToTop = () => {
        timeoutRef.current = setInterval(onScrollStep, delayInMs);
    }

    const renderGoTopIcon = () => {
        return (
            <div className={`go-top ${thePosition ? 'active' : ''}`} onClick={scrollToTop}>
                <i className="flaticon-up"></i>
            </div>
        )
    }

    return (
        <React.Fragment>
            {renderGoTopIcon()}
        </React.Fragment>
    )
}

export default GoTop;