import React, { useState } from 'react';
import ReactDOM from "react-dom";
import PropTypes from "prop-types"
import CountDown from './CountDown';
import '../../stylesheets/custom'
import '../../stylesheets/custom.css'

// import Percent20 from "./Percent20" /
// import Percent40 from "./Percent40"
// import Percent60 from "./Percent60"
//import Percent80 from "./Percent80"
// import Percent100 from "./Percent100" /

import Percent25 from "./Percent25"
import Percent50 from "./Percent50"
import Percent75 from "./Percent75"
import Percent100 from "./Percent100_2"

import testMP4 from './../../../videos/test.mp4'


const Solutions3 = (props) => {

    const [onilne, setOnline] = useState(false);


    return (
        <React.Fragment>
            <section className="projects-area bg-color-black pt-5 pb-5 ">
                <div className="container pb-5 border-line-bottom">
                    <div className="row justify-content-lg-between justify-content-md-center mt-1 ">
                        {false ?
                            <div className="col-11 pb-5 ">
                                <div className="d-flex justify-content-center">


                                    <a href="/transactions" className="px-5 py-5" >
                                        <img src={props.S3_ADDRESS + "webimages/buy_now_resize.png"} className="banner-img " alt="distribution" />
                                    </a>
                                </div>
                            </div>
                            :
                            <React.Fragment>
                                <div className="col-7 col-sm-8 col-md-7 col-lg-6 pb-3 pr-0  ">
                                    <div className="d-flex flex-column justify-content-center" >
                                        <h2 className="rockwell-font pb-3 color-FFFFFF  " style={{ fontSize: '52px' }}>
                                            THE
                                            <span style={{ color: '#F2356D' }}> TECH SCRIPT</span>
                                        </h2>

                                        <img src="https://ncash.s3.ap-southeast-1.amazonaws.com/website/res-bas-13.jpg" className="align-self-lg-center mr-5" style={{ width: "300px" }} />


                                    </div>

                                </div>
                                <div className="col-10  col-sm-10 col-md-7 col-lg-6 px-0 d-flex justify-content-center align-items-start">
                                    <div className="d-flex flex-column" >
                                    <ul className="ul-custom pl-0 pb-3" style={{ listStyleType: "none" }}>
                                        <li className="d-flex align-items-center">
                                            <div className=" rounded-full mr-1"></div>
                                            <span style={{ fontWeight: "700", fontSize: "20px", color: '#ffffff' }}>ERC-721 Contract</span>

                                        </li>
                                        <li className="d-flex align-items-center">
                                            <div className="rounded-full  mr-1"></div>
                                            <span style={{ fontWeight: "700", fontSize: "20px", color: '#ffffff' }}>ETH Blockchain</span>
                                        </li>
                                        <li className="d-flex align-items-center">
                                            <div className=" rounded-full  mr-1"></div>
                                            <span style={{ fontWeight: "700", fontSize: "20px", color: '#ffffff' }}>Rarities Set</span>
                                        </li>
                                        <li className="d-flex align-items-center">
                                            <div className=" rounded-full  mr-1"></div>
                                            <span style={{ fontWeight: "700", fontSize: "20px", color: '#ffffff' }}>Anti-Bot Tech</span>
                                        </li>


                                    </ul>

                                    <div className="d-flex flex-row  mb-3">


                                        <div className="d-flex col-6 py-0 pl-0 pr-3  m-0 flex-column rockwell-font">
                                            <div className="d-flex flex-column rockwell-font">
                                                <div className="rockwell-font ">
                                                    <p style={{ color: '#ffffff', fontSize: '30px', fontWeight: '500' }}>
                                                        6,666 NFTs
                                                    </p>
                                                </div>
                                                <div className="rockwell-font ">
                                                    <p style={{ color: '#71717A', fontSize: '16px', fontWeight: '500' }}>
                                                        All artworks are unique because A.I. is use to create them. This is beyond human capability.
                                                    </p>
                                                </div>
                                            </div>


                                            <div className="d-flex flex-column rockwell-font ">
                                                <div className="rockwell-font ">
                                                    <p style={{ color: '#ffffff', fontSize: '30px', fontWeight: '500' }}>
                                                        Drop Date
                                                    </p>
                                                </div>
                                                <div className="rockwell-font ">
                                                    <p style={{ color: '#71717A', fontSize: '16px', fontWeight: '500' }}>
                                                        Pre-sale: 09th April2022 - 17.00UTC
                                                        Mint: 10th April2022 - 17.00UTC
                                                    </p>
                                                </div>
                                            </div>



                                            <div className="d-flex flex-column rockwell-font ">
                                                <div className="rockwell-font ">
                                                    <p style={{ color: '#ffffff', fontSize: '30px', fontWeight: '500' }}>
                                                        Bills Denominations
                                                    </p>
                                                </div>
                                                <div className="rockwell-font ">
                                                    <p style={{ color: '#71717A', fontSize: '16px', fontWeight: '500' }}>
                                                        We have created bills in the following denominations: 0.2, 0.5, 1, 2, 5 10, 50. The larger the more rare. Which one will you get?
                                                    </p>
                                                </div>
                                            </div>


                                        </div>

                                        <div className="d-flex col-6 py-0 pl-3 pr-0  m-0 flex-column rockwell-font">
                                            <div className="d-flex flex-column rockwell-font">
                                                <div className="rockwell-font ">
                                                    <p style={{ color: '#ffffff', fontSize: '30px', fontWeight: '500' }}>
                                                        PRICE 0.077 ETH
                                                    </p>
                                                </div>
                                                <div className="rockwell-font ">
                                                    <p style={{ color: '#71717A', fontSize: '16px', fontWeight: '500' }}>
                                                        Value pricing as the cheapest denomination bill is 0.2ETH. Largest denomination is 50ETH.
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="d-flex flex-column rockwell-font ">
                                                <div className="rockwell-font ">
                                                    <p style={{ color: '#ffffff', fontSize: '30px', fontWeight: '500' }}>
                                                        Whitelisting
                                                    </p>
                                                </div>
                                                <div className="rockwell-font ">
                                                    <p style={{ color: '#71717A', fontSize: '16px', fontWeight: '500' }}>
                                                        We offer you the possibility to join the whitelist for a guaranteed spot on the pre-sale. Advantages are: no gas wars and price advantage.[link to WL page]
                                                    </p>
                                                </div>
                                            </div>



                                            <div className="d-flex flex-column rockwell-font ">
                                                <div className="rockwell-font ">
                                                    <p style={{ color: '#ffffff', fontSize: '30px', fontWeight: '500' }}>
                                                        IPFS Hosting
                                                    </p>
                                                </div>
                                                <div className="rockwell-font ">
                                                    <p style={{ color: '#71717A', fontSize: '16px', fontWeight: '500' }}>
                                                        All the images in the collections are hosted on IPFS. If you's like a preview of what's to come, see
                                                        <a href="/sneakpeak" className="rockwell-font"  > Gallery</a>
                                                    </p>
                                                </div>
                                            </div>



                                        </div>

                                    </div>


                                    </div>
                                </div>



                            </React.Fragment>

                        }

                    </div>

                </div>
            </section>

        </React.Fragment>
    )
}

Solutions3.propTypes = {
    artWorksData: PropTypes.array,
    S3_ADDRESS: PropTypes.string
};

export default Solutions3;
